import React from "react";
import { SiGooglemaps } from "react-icons/si";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Steps from "../assets/easy.png";
import emailjs from "@emailjs/browser";
import "../styles/styles.css";
import { Link } from "react-router-dom";
import ScrollToUp from "../components/ScrollToUp";
function ContactUs() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8ch5m55",
        "template_o540oma",
        form.current,
        "1qAHu7m0TXHIKVdN2"
      )
      .then(
        (result) => {
          alert("Your Email was successfully sent");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <div
        className="contactus-top row"
        style={{
          marginTop: "25px",
          backgroundColor: "ivory",
        }}
      >
        <div className="txt-contactus mx-3 col">
          <h4
            style={{
              color: "darkgreen",
              fontSize: "30px",
            }}
          >
            <b>Get In Touch!</b>
          </h4>
          <br />
          <p id="firstp">
            <SiGooglemaps />
            <b>Chtaura Highway, Lebanon</b>
          </p>
          <p id="seconedp">
            <a href="tel:+96176670005">
              <BsTelephone />
              +961-76670005
            </a>
          </p>
          <p id="lastp">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "mailto:info@wattechoenergy.com";
              }}
            >
              <BsEnvelope />
              info@wattechoenergy.com
            </Link>
          </p>

          <h4 style={{ color: "darkgreen", fontSize: "30px" }}>Hours</h4>
          <p style={{ fontSize: "large" }} className="my-3">
            MON-SAT: 8:00 AM - 4:00 PM
          </p>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="form-control d-flex flex-column contactus-form col"
        >
          <label for="fullName">Enter Your Full Name </label>
          <input
            type="text"
            placeholder="Full Name"
            name="user_name"
            id="fullName"
            className="text-success"
            minLength="5"
            required
          />
          <label for="subject">Subject</label>
          <input type="text" placeholder="Subject" id="subject" required />
          <label for="yourEmail">Enter Your Email </label>
          <input
            type="text"
            name="user_email"
            placeholder="E-mail"
            id="yourEmail"
            className="text-success"
            required
          />
          <label for="theText">Tell Us Your Needs </label>
          <textarea
            id="theText"
            cols="30"
            name="message"
            rows="10"
            className="text-success"
          ></textarea>
          <button type="submit" className="btn btn-warning my-2">
            Send Message
          </button>
        </form>
      </div>

      <div className="cusftr row">
        <h2
          style={{
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "ivory",
            color: "darkgreen",
          }}
        >
          Why Go <b>Solar</b> ?
        </h2>
        <div
          className="col"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#ffc107",
            border: "2px solid #ffc107",
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "darkgreen" }}
            >
              <Typography className="whySolarHead text-white">
                <b>Drastically reduce or even eliminate your electric bills</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography className="body-gosolar">
                PV solar panels enable you to generate your own free
                electricity, which is then used to power your household
                appliances and other electrical devices. This means that you
                won't need as much electricity from the grid so will save money
                on your bills
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ backgroundColor: "darkgreen" }}
            >
              <Typography className="whySolarHead text-white">
                <b>Solar increases property value</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography className="body-gosolar">
                Most homeowners know that solar panels reduce energy costs and
                carbon emissions. Those long-term savings help boost your home's
                property value
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              style={{ backgroundColor: "darkgreen" }}
            >
              <Typography className="whySolarHead text-white">
                <b>Solar Provides Clean energy</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography className="body-gosolar">
                No greenhouse gas emissions are released into the atmosphere
                when you use solar panels to create electricity. And because the
                sun provides more energy than we'll ever need, electricity from
                solar power is a very important energy source in the move to
                clean energy production
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
              style={{ backgroundColor: "darkgreen" }}
            >
              <Typography className="whySolarHead text-white">
                <b>Can be used in remote location</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography className="body-gosolar">
                Highway infrastructure such as streetlights and illuminated
                signage can benefit from off-grid solar systems, as there is no
                need to connect to the power lines. This makes installation,
                transportation, and deinstallation of temporary infrastructure
                much faster and easier
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panela-content"
              id="panel5a-header"
              style={{ backgroundColor: "darkgreen" }}
            >
              <Typography className="whySolarHead text-white">
                <b>Protect against rising energy costs</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography className="body-gosolar">
                When you create your own electricity with a solar power system,
                you don't have to draw as much from the utility grid, which can
                help you avoid those rising prices. In fact, going solar means
                you can lock in a specific price per watt (PPW) that won't
                change for the life of your system.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <h2 style={{ textAlign: "center", color: "darkgreen" }}>
          Steps of Going <b>GREEN</b>
        </h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={Steps}
            alt="Steps Of going green"
            style={{
              width: "800px",
              height: "500px",
              overflow: "hidden",
            }}
          />
        </div>
        <div className="contactus-bottom my-4">
          <div className="col">
            <h3
              style={{
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              We make going{" "}
              <b style={{ color: "darkgreen" }}>
                {" "}
                Solar <b style={{ color: "red" }}>Easy</b>
              </b>
            </h3>
          </div>
          <div className="stepsOfGoingGreen col">
            <div className="col">
              <p style={{ fontSize: "large" }}>
                We will be with you from the project start till the end and
                beyond...
                <br />
                <br />
                Our goal is help you go{" "}
                <b style={{ color: "darkgreen" }}>Green</b> go{" "}
                <b style={{ color: "#ffc107" }}>Solar</b>
                <br />
                <br />
                And Here is the process steps:
              </p>
              <p style={{ fontSize: "15px", color: "#ffc107" }}>Site Survey</p>
              <p style={{ fontSize: "15px", color: "darkgreen" }}>
                System Design
              </p>
              <p style={{ fontSize: "15px", color: "#ffc107" }}>Permitting</p>
              <p style={{ fontSize: "15px", color: "darkgreen " }}>
                Installation
              </p>
              <p style={{ fontSize: "15px", color: "#ffc107" }}>Inspections</p>
              <p style={{ fontSize: "15px", color: "darkgreen" }}>
                Utility Interconnection
              </p>
              <p style={{ fontSize: "15px", color: "#ffc107" }}>
                Monitoring and Maintenance
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToUp />
    </div>
  );
}

export default ContactUs;
