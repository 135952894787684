import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import Head from "../assets/cf-proj.jpg";
import Electrical from "../assets/electrical.jpeg";
import Panel from "../assets/module-building.jpeg";
import Renewable from "../assets/renewable.jpeg";
import Asystem from "../assets/alarm-system.png";
import EnergyManagement from "../assets/energymanage.jpeg";
import Services from "../assets/services-maintanance.png";
import Proj2 from "../assets/projects.jpeg";
import Goal1 from "../assets/goal1.jpeg";
import Goal2 from "../assets/goal2.jpeg";
import Goal3 from "../assets/carbon.png";
import Goal4 from "../assets/goal4.jpeg";
import Goal5 from "../assets/goal5.jpeg";
import Solution from "../assets/solution.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import ScrollToUp from "../components/ScrollToUp";
import HiringNow from "../components/HiringNow";
import "../App.css";
function Homepage() {
  const navigate = useNavigate();
  function handleProjects(e) {
    e.preventDefault();
    navigate("./Projects");
    window.scrollTo(0, 0);
  }
  function discoverProducts(e) {
    e.preventDefault();
    navigate("./Products");
    window.scrollTo(0, 0);
  }
  function getQuote(e) {
    e.preventDefault();
    navigate("./Contact");
    window.scrollTo(0, 0);
  }
  function handleService(e) {
    e.preventDefault();
    navigate("./Services");
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: `url(${Head})`,
          height: "580px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="mask">
          <div className="d-flex justify-content-center align-items-center ">
            <div className="text-white">
              <h1
                className="mb-3 animate-char"
                style={{
                  marginTop: "100px",
                  fontSize: "50px",
                }}
              >
                WELCOME TO THE ERA OF SOLAR ENERGY
              </h1>

              <h4
                className="mb-3 h3"
                style={{
                  animation: "fadeIn 6s",
                  color: "white",
                }}
              >
                WEE SERVE YOU DESERVE
              </h4>
              <MDBBtn
                onClick={discoverProducts}
                outline
                size="lg"
                color="light"
                className="hero-button btn btn-warning"
              >
                Discover Our Products
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto">
        <div className="welcome">
          <h1 style={{ animation: "fadeIn 5s" }}>
            <b className="watt-echo my-4">WATT ECHO ENERGY </b>
          </h1>
          <p className="welcome-parag" style={{ animation: "fadeIn 8s" }}>
            The Watt Echo Energy company was established in 2022 to support the
            growth of Renewable Energy Services in the region, and provide solar
            and electrical services.
          </p>
        </div>
        <HiringNow />
        <div className="my-3">
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "Ivory",
              color: "darkgreen",
            }}
          >
            Our <b>Services</b>
          </h2>
        </div>

        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide my-5"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="10000">
              <img
                src={Electrical}
                className="d-block w-100"
                alt="Engineerig departmnet"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>Engineering Department</h5>
                <p>
                  <b>
                    Professional environment for power system studies, and
                    solutions as per national standards.
                  </b>
                </p>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src={Panel}
                className="d-block w-100"
                alt="Module Building"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>Panel Building</h5>
                <p>
                  <b>All types of industrial solutions & control</b>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Renewable}
                className="d-block w-100"
                alt="Renewable Energies"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>Renewable Energy</h5>
                <p>
                  <b>
                    Solar systems for industrial, agricultural, and residential
                    projects.
                  </b>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Asystem}
                className="d-block w-100"
                alt="CCTV"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>CCTV, Alarm Systems</h5>
                <p>
                  <b>
                    Install all kinds of cameras and alarm systems to protect
                    your home and interests.
                  </b>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={EnergyManagement}
                className="d-block w-100"
                alt="Energy Study"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>Energy Management</h5>
                <p>
                  <b>Load profile study, and dispatch.</b>
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Services}
                className="d-block w-100"
                alt="Maintenance"
                style={{
                  height: "400px",
                }}
              />
              <div
                className="carousel-caption pic-mask"
                style={{ color: "white" }}
              >
                <h5>Services & Maintenance</h5>
                <p>
                  <b>Qualified teams for system troubleshooting.</b>
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div
          style={{
            border: "1px solid darkgreen",
          }}
        >
          <Accordion>
            <AccordionSummary
              style={{ backgroundColor: "darkgreen" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                <b style={{ color: "white" }}>Mission & Vision</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "ivory" }}>
              <Typography style={{ fontSize: "20px" }}>
                <b
                  style={{
                    color: "darkgreen",
                  }}
                >
                  Watt Echo Energy Mission
                </b>{" "}
                is to transform the lives of our customers and the community in
                which they live by providing energy solutions that are
                attainable, sustainable, and economical.
                <br />
                <p style={{ textAlign: "right" }}>
                  تحويل حياة زبائننا ومجتمعنا من خلال توفير حلول ممكنة ، مستدامة
                  واقتصادية للطاقة الشمسية
                </p>
                <br />
                <br />
                <b
                  style={{
                    color: "darkgreen",
                  }}
                >
                  Watt Echo Energy Vision
                </b>{" "}
                is to fulfill our energy needs with renewable energy and to
                provide the most compelling value in the solar industry with
                cost effective sustainable energy solutions.
                <br />
                <p style={{ textAlign: "right" }}>
                  الاستفادة من الطاقة المتجددة لتلبية احتياجاتنا وتقديم افضل
                  قيمة وجودة في مجال الطاقة الشمسية باقل تكلفة لحلول مستدامة
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="mt-4"
          style={{
            backgroundImage: `url(${Solution})`,
            height: "400px",
          }}
        >
          <div>
            <h1 className="text-white animate-charcter">
              Solutions You Need !
            </h1>
            <br />
          </div>
          <MDBBtn
            onClick={getQuote}
            outline
            size="lg"
            color="light"
            className="hero-button btn btn-warning mt-3"
          >
            Get Quote
          </MDBBtn>
        </div>

        <h2
          style={{
            textAlign: "center",
            marginTop: "25px",
            backgroundColor: "ivory",
            color: "darkgreen",
          }}
        >
          Our <b>GOALS</b>
        </h2>
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Goal1}
                className="d-block w-100"
                alt="..."
                style={{
                  height: "300px",
                  borderRadius: "8px",
                  width: "100%",
                }}
              />
              <div className="carousel-caption">
                <h5
                  style={{
                    fontSize: "large",
                  }}
                >
                  Provide the best and most effective technology solutions,
                  while always looking to save our customer's money
                </h5>
              </div>
            </div>

            <div className="carousel-item">
              <img
                src={Goal2}
                className="d-block w-100"
                alt="Longevity"
                style={{
                  height: "300px",
                  borderRadius: "8px",
                }}
              />
              <div className="carousel-caption">
                <h5>
                  Maintain a company that is focused on longevity, such that it
                  will continue to provide its customers with top quality
                  services over a span of decades
                </h5>
              </div>
            </div>

            <div className="carousel-item">
              <img
                src={Goal3}
                className="d-block w-100"
                alt="0% carbon"
                style={{
                  height: "300px",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div className="carousel-item">
              <img
                src={Goal4}
                className="d-block w-100"
                alt="Best service"
                style={{
                  height: "300px",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div className="carousel-item">
              <img
                src={Goal5}
                className="d-block w-100"
                alt="Ethics"
                style={{
                  height: "300px",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div className="card my-3">
          <h5
            className="card-header"
            style={{ backgroundColor: "darkgreen", color: "white" }}
          >
            Our Services
          </h5>
          <div className="card-body" style={{ backgroundColor: "ivory" }}>
            <h5 className="card-title">Energy Management</h5>
            <p className="card-text">
              Deep study of consumption and planning for energy productivity
              before and after operation
            </p>
            <button className="btn btn-warning mb-0" onClick={handleService}>
              Show More
            </button>
          </div>
        </div>
        <div className="card my-3">
          <h5
            className="card-header"
            style={{ color: "white", backgroundColor: "darkgreen" }}
          >
            Our Services
          </h5>
          <div className="card-body" style={{ backgroundColor: "ivory" }}>
            <h5 className="card-title">Engineering Department</h5>
            <p className="card-text">
              Professional environment for power system studies, and solutions
              as per national standards.
              <br />
            </p>
            <button className="btn btn-warning mb-0" onClick={handleService}>
              Show More
            </button>
          </div>
        </div>
      </div>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          backgroundColor: "ivory",
          color: "darkgreen",
        }}
      >
        Our <b>Projects</b>
      </h2>
      <div
        className="projects"
        style={{
          backgroundImage: `url(${Proj2})`,
          height: "300px",
          borderRadius: "10px",
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <button className="btn btn-warning" onClick={handleProjects}>
          Our Projects
        </button>
      </div>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          backgroundColor: "ivory",
          color: "darkgreen",
        }}
      >
        Our Customer's <b>Testimony</b>
      </h2>
      <div className="testimony">
        <Card.Group>
          <Card>
            <Card.Content>
              <Card.Header>Cedars Farm</Card.Header>
              <Card.Meta>Customer</Card.Meta>
              <Card.Description>
                Since the installation of the solar modules, my business has
                experienced a significant reduction in energy costs. The energy
                produced by the solar modules has been able to power all of my
                business needs, which has resulted in a drastic reduction in my
                energy bills. Big thank for Watt Echo Energy.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header content="La Clinica" />
              <Card.Meta content="Customer" />
              <Card.Description content="I would highly recommend Watt Echo Energy to any business or homeowner who is looking for a reliable and professional solar energy company. Their team was knowledgeable, efficient, and provided exceptional service throughout the entire installation process." />
            </Card.Content>
          </Card>

          <Card>
            <Card.Content
              header="Quick Meal"
              meta="Customer"
              description="I was impressed with the team behavior, installation, and especially the fast service after i contacted them."
            />
          </Card>

          <Card
            header="Mr.Issmat Mourad"
            meta="Customer"
            description="The team that was assigned to my project was professional, knowledgeable, and efficient. They made sure to answer all of my questions and concerns before, during, and after the installation process. They provided clear and concise explanations of the equipment and installation procedures."
          />
        </Card.Group>
      </div>
      <ScrollToUp />
    </>
  );
}
export default Homepage;
