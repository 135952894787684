import React from "react";
import { useEffect, useState } from "react";
import "../App.css";

function ScrollToUp() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {showButton && (
        <button onClick={scrollUp} className="back-to-top">
          &#8679;
        </button>
      )}
    </>
  );
}
export default ScrollToUp;
