import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardOverlay,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Deye from "../assets/deye-100KW.png";
import Battery8k from "../assets/felicity-battery.jpeg";
import Huawei from "../assets/huawei-100kw.png";
import Ja from "../assets/Jasolar.jpeg";
import Cover from "../assets/cover.jpeg";
import Byd from "../assets/byd-battery.jpeg";
import Voltronic from "../assets/voltronic-inverter.jpeg";
import Veichi from "../assets/veichi-inverter.jpeg";
import Indexbattery from "../assets/index-battery.jpeg";
import Leosh from "../assets/leosh-battery.png";
import Smartlogger from "../assets/smart-logger.jpeg";
import Cards from "../components/Cards";
import Deif from "../assets/deif.jpeg";
import GrovoltInv from "../assets/grovolt.png";
import MeritSun from "../assets/meritSun.png";
import ScrollToUp from "../components/ScrollToUp";
function Products() {
  return (
    <div>
      <div>
        <MDBCard
          background="dark"
          className="text-white"
          style={{
            marginTop: "20px",
          }}
        >
          <MDBCardImage overlay src={Cover} alt="..." />
          <MDBCardOverlay>
            <MDBCardTitle
              style={{
                color: "green",
              }}
            >
              WEE SERVE YOU DESERVE
            </MDBCardTitle>
          </MDBCardOverlay>
        </MDBCard>
        <Cards
          imagee={Deye}
          namee="Deye Inverter"
          specs1="97% Efficiency"
          specs2="5 Years Warranty"
          specs3="Perfect Choice For Modular Power Systems"
          specs4="Up To 16 Parallel Inverters"
          parag="is an efficient, high-performance solar inverter. Its integrated solar and battery power storage allows you to
           build a cost-effective solar power system. It also enables you to use this power to run household appliances and electronics."
        />

        <Cards
          imagee={Huawei}
          namee="Huawei Inverter"
          specs1="100KW"
          specs2="5 Years Warranty"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag=" Huawei SUN2000-100KTL hybrid network inveter new digital technology and Internet connectivity. It
          provides solar power generation optimization with an
          integrated plug and play battery interface and power management for smart homes."
        />

        <Cards
          imagee={Voltronic}
          namee="Voltronic Inverter"
          specs1="5.5KW-6.0KW"
          specs2="Built-in anti-dust kit for harsh environment"
          specs3="Best Quality Standards"
          specs4="Inverter Running without batteries"
          parag=" Voltronic Inverter is a multi-function inverter/charger, combining functions of inverter, solar charger and battery charger
          to offer uninterruptible power support with portable size."
        />
        <Cards
          imagee={GrovoltInv}
          namee="Grovolt Inverter"
          specs1="Pure sinewave solar inverter On-grid/Off-Grid"
          specs2="Battery independent design"
          specs3="Best Quality Standards"
          specs4="Built-in Wi-Fi with iOS & Android App"
          parag="is a high efficiency solar inverter."
        />
        <Cards
          imagee={Veichi}
          namee="Veichi Inverter"
          specs1="The new IOT platform design is simple and generous"
          specs2="Support GPS positioning, WIFI data connection, offline data storage"
          specs3="Best Quality Standards"
          specs4="Both stand-alone and group cabinets can meet the demand"
          parag="  Veichi solar water pump inverter is a high-efficiency solar water pump controller which can make full use of solar
          energy to drive water pumps for agricultural irrigation, water supply system, fountains, groundwater lowering and
          others."
        />
        <Cards
          imagee={Byd}
          namee="BYD Lithium Battery"
          specs1="5 Years Warranty"
          specs2="Top in the world"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag=" BYD Battery-Box Premium LVL is a lithium iron phosphate (LFP) battery for use with
          an external inverter. Thanks to its control and communication port (BMU), the Battery-Box Premium LVL
          scales to meet the project requirements, no matter how large they may be."
        />
        <Cards
          imagee={MeritSun}
          namee="Meritsun Lithium Battery"
          specs1="7 Years Warranty"
          specs2="6000 cycle"
          specs3="Best Quality Standards"
          specs4="WEE stands behind this battery"
          parag="is one of the best batteris in the world. Watt Echo Energy stands with this battery and support it. It's one of the best seller product with high quality standards."
        />
        <Cards
          imagee={Battery8k}
          namee="Lithium Felicity Battery"
          specs1="5 Years Warranty"
          specs2="170A-8.7KW"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag=" Felicity Lithium is a Rechargeable Battery and one of the best Lithium
          batteries sold in Lebanon by Watt Echo Energy. Additionally, you can charge this Lithium battery using
          Solar panels. Thus have a sustainable energy supply."
        />
        <Cards
          imagee={Indexbattery}
          namee="Index Acid Battery"
          specs1="12V/200Ah Inverter Battery"
          specs2="68.4 A Maximum charge"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag="Index Nxt 200Ah/12V AGM Inverter Battery is made with thicker and
          heavier plate with 99% pure red lead for higher charge. With a weight of 70 kg, they also come with 1 year of
          warranty and up to 4 to 5 years of lifespan"
        />
        <Cards
          imagee={Leosh}
          namee="Leoch carbon Battery"
          specs1="12V/200Ah Inverter Battery"
          specs2="57.9 KG"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag="  Leoch Lead Carbon batteries, LC series, are Carbon AGM Valve-Regulated Lead-Acid
          batteries that have been optimized for renewable energy applications. Engineered using Lead Carbon technology
          that reduces shedding of the active material from the negative plates, LC batteries offer very high cyclic
          performance."
        />
        <Cards
          imagee={Ja}
          namee="JA Solar Panels"
          specs1="30 Years Performance"
          specs2="10 Years Warranty"
          specs3="Best Quality Standards"
          specs4="Competitive Price"
          parag="JA Solar is one of the largest solar panel manufacturers with
          third-largest solar module capacity (reportedly 42.5GW/year in 2021). JA Solar has focused on producing
          a mid-market solar panel for the masses that combines reliability with an affordable price point."
        />
        <Cards
          imagee={Deif}
          namee="Deif AGC 150"
          specs1="Accuracy class 1.0"
          specs2="Operating temperature -25 to 70"
          specs3="Storage temperature -40 to 70"
          specs4="Operating altitude 0 to 4000 m above sea level"
          parag="AGC 150 Genset controller containing all necessary functions for
          control and protection of a genset. The slim design makes the genset controller suitable for paralleling
          even small gensets thus the AGC 150 is integrable in nearly all types of gensets."
        />
        <Cards
          imagee={Smartlogger}
          namee="Huawei Smart Logger"
          specs1="Relative Humidity 5% ~ 95%"
          specs2="The Best Quality Standards"
          specs3="Storage temperature -40 to 70"
          specs4="Operating altitude 0 to 4000 m above sea level"
          parag=" Huawei SmartLogger is dedicated for monitoring and managing the PV power
          generating system. It converges all ports, converts protocols, collects and stores data, and centrally
          monitors and maintains the PV power generating system."
        />
      </div>
      <ScrollToUp />
    </div>
  );
}
export default Products;
