import * as React from "react";
import { SiGooglemaps } from "react-icons/si";
import "../styles/styles.css";
function Project(props) {
  return (
    <>
      <div className="project1all row m-2">
        <div
          id={props.idd}
          className="col-lg-6 col-md-6 col-sm-12 carousel slide firstproject my-2"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target={props.target}
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target={props.target}
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target={props.target}
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target={props.target}
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={props.img1}
                className="d-block w-100"
                alt="proj1"
                style={{ height: "460px", width: "345px" }}
              />
              <div className="carousel-caption pic-maskk">
                <h5>{props.txt1}</h5>
                <p>{props.desc1}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={props.img2}
                className="d-block w-100"
                alt="..."
                style={{ height: "460px", width: "345px" }}
              />
              <div className="carousel-caption pic-maskk">
                <h5>{props.txt2}</h5>
                <p>{props.desc2}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={props.img3}
                className="d-block w-100"
                alt="..."
                style={{ height: "460px", width: "345px" }}
              />
              <div className="carousel-caption pic-maskk">
                <h5>{props.txt3}</h5>
                <p>{props.desc3}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={props.img4}
                className="d-block w-100"
                alt="..."
                style={{ height: "460px", width: "345px" }}
              />
              <div className="carousel-caption pic-maskk">
                <h5>{props.txt4}</h5>
                <p>{props.desc4}</p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={props.target}
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={props.target}
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          <h3>
            Customer: <b>{props.customer}</b>
          </h3>
          <h4>
            <b>{props.system}</b>
          </h4>
          <h6>
            <ul className="proj1specs">
              <li>{props.spec1}</li>
              <li>{props.spec2}</li>
              <li>{props.spec3}</li>
              <li>{props.spec4}</li>
            </ul>
            Date: <b>{props.datee}</b>
            <br />
            <span>
              <SiGooglemaps /> {props.loc}
            </span>
          </h6>
        </div>
      </div>
    </>
  );
}

export default Project;
