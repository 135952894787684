import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBContainer,
  MDBRipple,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { useState } from "react";
function Cards(props) {
  const [isShown, setIsShown] = useState(false);
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  return (
    <div>
      <div>
        <MDBContainer fluid>
          <MDBRow
            className="col justify-content-center mb-3"
            style={{
              marginTop: "20px",
            }}
          >
            <MDBCol md="12" xl="10">
              <MDBCard className="shadow-0 border rounded-3">
                <MDBCardBody style={{ backgroundColor: "ivory" }}>
                  <MDBRow>
                    <MDBCol md="12" lg="3" className="mb-4 mb-lg-0">
                      <MDBRipple
                        rippleColor="light"
                        rippleTag="div"
                        className="bg-image rounded hover-zoom hover-overlay"
                      >
                        <MDBCardImage
                          src={props.imagee}
                          fluid
                          className="w-100"
                        />
                      </MDBRipple>
                    </MDBCol>
                    <MDBCol md="6">
                      <h5 style={{ color: "#ffc107" }}>{props.namee}</h5>
                      <div className="d-flex flex-row">
                        <div className="text-danger mb-1 me-2"></div>
                      </div>
                      <div className="mt-1 mb-0 text-muted small">
                        <span>{props.specs1}</span>
                        <span className="text-primary"> • </span>
                        <span>{props.specs2}</span>
                        <span classNAme="text-primary"> • </span>
                        <span>
                          {props.specs3}
                          <br />
                        </span>
                      </div>
                      <div className="mb-2 text-muted small">
                        <span>{props.specs4}</span>

                        <br />
                      </div>
                    </MDBCol>
                    <MDBCol
                      md="6"
                      lg="3"
                      className="border-sm-start-none border-start"
                    >
                      <h6 className="text-success">
                        Delivery All Over Lebanon
                      </h6>
                      <div className="d-flex flex-column mt-4">
                        <button
                          size="sm"
                          onClick={handleClick}
                          id="btn"
                          className="btn btn-warning"
                        >
                          {isShown ? "Show Less" : "Show More"}
                        </button>
                        {isShown && (
                          <div>
                            <p className="text mb-4 mb-md-0">
                              <b
                                style={{
                                  color: "darkgreen",
                                }}
                              >
                                {props.namee}
                              </b>{" "}
                              {props.parag}
                            </p>
                          </div>
                        )}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}
export default Cards;
