import Navbar from "./components/Navbar";
import AppRouter from "./App.router";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div>
        <AppRouter />
      </div>
      <Footer />
    </div>
  );
}

export default App;
