import { Routes, Route } from "react-router-dom";

import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Services from "./pages/Services";

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/services" element={<Services />} />
    </Routes>
  );
}

export default AppRouter;
