import React from "react";
function ServiceT(props) {
  return (
    <>
      <div
        className="row row-card"
        style={{
          borderBottom: "1px solid darkgreen",
        }}
      >
        <div className="col electrical-dpt mb-3">
          <img src={props.picture} alt="---" />
        </div>
        <p className="col eng-parag">
          {props.engParag}
          <p style={{ textAlign: "right" }}>{props.arParag}</p>
        </p>
      </div>
    </>
  );
}
export default ServiceT;
