// import React from "react";
import Mostafa from "../assets/emm.jpg";
import Rachid from "../assets/rasheed-h.jpeg";
import AliS from "../assets/AliSwaidd.jpeg";
import Mj from "../assets/mohammad-jarrah.jpeg";
import Koj from "../assets/Khj.jpeg";
import SH from "../assets/sara.jpeg";
import FR from "../assets/Fadi Rayya.jpeg";
import MMJ from "../assets/MMJ.png";
import ScrollToUp from "../components/ScrollToUp";
import { Link } from "react-router-dom";
import "../styles/abtus.css";
import React from "react";

const AbtUs = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card profile-card-1">
                <img
                  src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb"
                  alt="profile-sample1"
                  className="background"
                />
                <img src={Koj} alt="CEO" className="profile" />
                <div className="card-content">
                  <h2>
                    Khaled Jarrah<small>CEO</small>
                  </h2>
                  <div className="icon-block">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "mailto:kjarrah@wattechoenergy.com";
                      }}
                    >
                      {" "}
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&h=650&w=940"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img src={Mj} alt="Mohammad Jarrah" className="profile" />
                </div>
                <div className="card-content">
                  <h2>
                    Eng. Mohammad Jarrah <small>COO</small>
                  </h2>
                  <div className="icon-block">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "mailto:mjarrah@wattechoenergy.com";
                      }}
                    >
                      {" "}
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card profile-card-1">
                <img
                  src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb"
                  alt="profile-sample1"
                  className="background"
                />
                <img src={Rachid} alt="CEE" className="profile" />
                <div className="card-content">
                  <h2>
                    Eng. Rachid Harb<small>CEE</small>
                  </h2>
                  <div className="icon-block">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "mailto:rharb@wattechoenergy.com";
                      }}
                    >
                      {" "}
                      <i className="fa fa-google-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top employees"
                    src={SH}
                    alt="Sara Hasna"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">Sara Hasna</h5>
                  <p className="card-text">Operation Officer.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top employees"
                    src={Mostafa}
                    alt="Moustafa Md"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">Eng. Mustafa Muhieddine</h5>
                  <p className="card-text">Electrical Design Engineer.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top employees"
                    src={AliS}
                    alt="Ali Swaid"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">Eng. Ali Swaid</h5>
                  <p className="card-text">Electrical Site Engineer.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top employees"
                    src={FR}
                    alt="Fadi Rayya"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">Eng. Fadi Rayya</h5>
                  <p className="card-text">Senior Electrical Engineer.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top employees"
                    src={MMJ}
                    alt="Mohammad Jaji"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">Eng. Mohammad Al-Jaji</h5>
                  <p className="card-text">Procurement Engineer.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToUp />
      </section>
    </>
  );
};

export default AbtUs;
