import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/styles.css";
import Newlogo from "../assets/newlogo.png";

function CustomLink({ to, children, ...props }) {
  const path = window.location.pathname;
  return (
    <li className={path === to ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
const pages = ["about", "products", "contact", "projects", "services"];
function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  function goHomepage(e) {
    e.preventDefault();
    navigate("/");
  }
  return (
    <AppBar position="static" className="mui-nav">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={Newlogo}
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            className="new-logo mx-2"
            alt="logo"
          />

          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={goHomepage}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Watt Echo Energy
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <CustomLink
                  className="text-capitalize px-3 py-3 custom-xs mx-2 my-4 text-dark h4"
                  onClick={handleCloseNavMenu}
                  key={page + index}
                  to={`/${page}`}
                >
                  {page}
                </CustomLink>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            className="txt-logo"
            noWrap
            component="a"
            onClick={goHomepage}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Watt Echo Energy
          </Typography>
          <Box
            className="justify-content-end"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page, index) => (
              <CustomLink
                className="text-capitalize p-2 mx-3"
                key={page + index}
                sx={{ my: 2, color: "white", display: "block" }}
                to={`/${page}`}
              >
                {page}
              </CustomLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
