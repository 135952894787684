import React from "react";
import Logo from "../assets/logo-remove.png";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import "../styles/styles.css";
function Footer() {
  return (
    <div className="ftr">
      <footer className="footer">
        <ul>
          <Link to="https://www.instagram.com/watt_echo_energy/">
            {
              <InstagramIcon
                style={{
                  fontSize: "40px",
                }}
                url="https://www.instagram.com/watt_echo_energy/"
              />
            }
          </Link>

          <Link to="https://www.facebook.com/profile.php?id=100088079282912">
            {
              <FacebookIcon
                style={{
                  fontSize: "40px",
                }}
                url="https://www.facebook.com/profile.php?id=100088079282912"
              />
            }
          </Link>

          <Link to="https://www.linkedin.com/company/watt-echo-energy/">
            {
              <LinkedInIcon
                style={{
                  fontSize: "40px",
                }}
                url="https://www.linkedin.com/company/watt-echo-energy/"
              />
            }
          </Link>
          <li>
            {" "}
            <FloatingWhatsApp
              phoneNumber="+96176670005"
              accountName="Watt Echo Energy"
              avatar={Logo}
              allowEsc
              allowClickAway
              notification
              notificationSound
            />
          </li>
        </ul>
        <div>
          <li className="copyright">Watt Echo Energy &copy; 2023</li>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
