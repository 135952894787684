import React from "react";
import "../styles/styles.css";
import { Link } from "react-router-dom";
const HiringNow = () => {
  return (
    <div className="card text-center hiringnow">
      <div className="card-body">
        <h5 className="card-title hiringnowtitle">
          <b> Open Internship Positions At Watt Echo Energy</b>
        </h5>
        <p className="card-text">
          Don't miss out on this incredible opportunity to kickstart your career
          in the renewable energy sector with Watt Echo Energy.
          <br /> Join us in making a positive impact on our planet and shaping a
          sustainable future for generations to come.
        </p>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto:info@wattechoenergy.com";
          }}
        >
          Send Your CV
        </Link>
      </div>
    </div>
  );
};

export default HiringNow;
