import React from "react";
import Electrical from "../assets/electrical.jpeg";
import Panel from "../assets/module-building.jpeg";
import Renewable from "../assets/renewable.jpeg";
import Asystem from "../assets/alarm-system.png";
import EnergyManagement from "../assets/energymanage.jpeg";
import services from "../assets/services-maintanance.png";
import ServiceT from "../components/ServiceT";
import ScrollToUp from "../components/ScrollToUp";
function Services() {
  return (
    <div style={{ backgroundColor: "ivory" }}>
      <h1 className="text-center text-warning"> Our Services</h1>
      <ServiceT
        picture={Electrical}
        engParag="The Engineering Department includes a study of electric power
            supply, signal processing, automatic control system,power generation
            and distribution, and renewable energies."
        arParag="شمل قسم الهندسة دراسة لطاقة الكهربائية و امداداتها و معالجة
                الاشارات و نظام التحكم الآلي و توليد الطاقة و توزيعها و الطاقة
                المتجددة"
      />
      <ServiceT
        picture={Panel}
        engParag="Module Building which is collecting and installing all types of
            tables with the best equipment, and studies on the sizes and types
            needed for any required project."
        arParag="جمع و تركيب جميع انواع التابلوات بأفضل المعدات و الدراسات حول احجام
                و نوعيات الآزمة لاي مشروع
                "
      />
      <ServiceT
        picture={Renewable}
        engParag="Renewable Energy which is providing solutions for industrial
            and agricultural projects, in addition to home projects, by using
            renewable energy and limiting the use of non-renewable energy which is
            done by adopting modern machines and advanced studies."
        arParag="تقديم حلول للمشاريع الصناعية و الزراعية بالاضافة الى المشاريع
                المنزلية و ذلك باستعمال الطاقة المتجددة و الحد من استخدام الطاقة
                الغير المتجددة باتباع الآت حديثة و دراسات متطورة
                "
      />
      <ServiceT
        picture={Asystem}
        engParag="CCTV, and Alarm Systems because security is important, we
            install all kinds of cameras and alarm systems to protect your home
            and interests."
        arParag="                تركيب جميع انواع الكاميرات و أنظمة الإنذار لحماية منازلكم و مصالحكم
                "
      />
      <ServiceT
        picture={EnergyManagement}
        engParag="Energy Management, which is a deep study of consumption and
            planning for energy productivity before and after operation, with a
            study of energy distribution and storage."
        arParag="دراسة عميقة للإستهلاك و تخطيط لإنتاجية الطاقة الازمة قبل و بعد
                التشغيل مع دراسة توزيع و تخزين للطاقة
                "
      />
      <ServiceT
        picture={services}
        engParag="Services & Maintenance, which includes preventive maintenance,
            emergency repairs, scheduled servicing, testing and component
            replacements, and operation of building electrical systems for
            industries and others."
        arParag="تشمل الخدمات المقدمة الصيانة الوقائية والإصلاحات الطارئة والخدمة
                المجدولة والاختبار واستبدال المكونات وتشغيل الأنظمة الكهربائية
                "
      />
      <ScrollToUp />
    </div>
  );
}
export default Services;
