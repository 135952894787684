import * as React from "react";
import Proj12 from "../assets/Proj11.jpeg";
import Proj13 from "../assets/proj-3.jpeg";
import Proj14 from "../assets/proj-4.jpeg";
import Proj15 from "../assets/proj-5.jpeg";
import Proj21 from "../assets/proj2-2.jpeg";
import Proj22 from "../assets/proj2-3.jpeg";
import Proj23 from "../assets/proj2-4.jpeg";
import Proj24 from "../assets/electricalroom.jpeg";
import Proj31 from "../assets/proj3-1.jpeg";
import Proj32 from "../assets/proj3-2.jpeg";
import Proj33 from "../assets/proj3-3.jpeg";
import Proj34 from "../assets/proj3-4.jpeg";
import Proj41 from "../assets/proj41.jpeg";
import Proj42 from "../assets/proj42.jpeg";
import Proj43 from "../assets/proj43.jpeg";
import Proj44 from "../assets/proj44.jpeg";
import Proj51 from "../assets/proj51.jpeg";
import Proj52 from "../assets/proj52.jpeg";
import Proj53 from "../assets/proj53.jpeg";
import Proj61 from "../assets/proj61.jpeg";
import Proj62 from "../assets/proj62.jpeg";
import Project from "../components/Project";
import Roofv from "../assets/roofv.PNG";
import WorkP from "../assets/workOnProgress.PNG";
import PanelB from "../assets/panelBuilding.PNG";
import Module from "../assets/moduleBuilt.PNG";
import logo1 from "../assets/logo-remove.png";
import United1 from "../assets/united1.jpeg";
import United2 from "../assets/united2.jpeg";
import United3 from "../assets/united3.jpeg";
import United4 from "../assets/united4.jpeg";
import "../styles/styles.css";
import ScrollToUp from "../components/ScrollToUp";
function Projects() {
  return (
    <>
      <Project
        idd="carouselExampleCaptions"
        target="#carouselExampleCaptions"
        img1={Proj12}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={Proj13}
        txt2="Batteries"
        desc2="BYD Lithium Batteries"
        img3={Proj14}
        txt3="Electrical Room"
        desc3="Overview of the electrical room"
        img4={Proj15}
        txt4="MDB"
        desc4="Overview of the MDB installation"
        customer="Cedars Farm"
        system="Hybrid Solar System"
        spec1="152 KWp"
        spec2="279 * JA Solar Panels 545 Watts"
        spec3="10 * Inverters Deye 12 KW"
        spec4="40 * BYD 5KWH Lithium Batteries"
        datee="01/09/2022"
        loc="Qab-Elias"
      />
      <Project
        idd="carouselExampleCaption"
        target="#carouselExampleCaption"
        img1={Proj21}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={Proj22}
        txt2="Batteries"
        desc2="Felicity Lithium Batteries"
        img3={Proj23}
        txt3="Deye Inverter"
        desc3="Deye Inverters Installed by our engineering team"
        img4={Proj24}
        txt4="Electrical Room"
        desc4="Overview of the module building, and the electrical room"
        customer="La Clinica"
        system="Hybrid Solar System"
        spec1="25 KWp"
        spec2="46 * JA Solar Panels 545 Watts"
        spec3="2 * Inverters Deye 12 KW"
        spec4="3 * Felicity Lithium Batteries"
        datee="10/09/2022"
        loc="Chtaura"
      />
      <Project
        idd="carouselExampleCaptionss"
        target="#carouselExampleCaptionss"
        img1={Proj31}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={Proj32}
        txt2="Electrical Room"
        desc2="Overview of the module building, and the electrical room"
        img3={Proj33}
        txt3="Deye Inverter & Felicity Battery"
        desc3=" Deye Inverter & Felicity batteries Installed by our engineering team"
        img4={Proj34}
        txt4="Deye Inverters"
        desc4="Deye Inverters installed by engineerig team"
        customer="Cedars Farm Butchery"
        system="Hybrid Solar System"
        spec1="24 KWp"
        spec2="46 * JA Solar Panels 545 Watts"
        spec3="4 * Inverters Deye 5 KW"
        spec4="4 * Felicity Lithium Batteries"
        datee="03/01/2023"
        loc="Chtaura"
      />
      <Project
        idd="carouselExample"
        target="#carouselExample"
        img1={Proj41}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={Proj42}
        txt2="Panel Building"
        desc2="Overview of Panel Building"
        img3={Proj43}
        txt3="Installation Of Inverter and the module"
        desc3="Solar Pump Inverter with Panel Built"
        img4={Proj44}
        txt4="Overview of the whole module"
        desc4="Solar pump inverter and panel buit by our engineerig team"
        customer="Mr. Issmat Mourad"
        system="Solar Pumping System"
        spec1="8.7 KWp"
        spec2="16 * JA Solar Panels 545 Watts"
        spec3="1 * Inverter Veichi 4KW"
        datee="09/03/2023"
        loc="Qab-Elias"
      />
      <Project
        idd="carouselExamples"
        target="#carouselExamples"
        img1={Proj51}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={Proj52}
        txt2="Solar Panels"
        desc2="A close view for the JA solar Panels"
        img3={Proj53}
        txt3="Overview of the whole system"
        desc3="Inverter & Batteries"
        img4={logo1}
        txt4="Watt Echo Energy"
        desc4="Your First choice for Electrical and solar solutions"
        customer="Mr. Nourddine Hajar"
        system="Hybrid Solar System"
        spec1="2.18 KWp"
        spec2="4 * JA Solar Panels 545 Watts"
        spec3="1 * Inverter Voltronic VM3-4KW"
        spec4="2 * Leosh carbon battery 200Ah"
        datee="23/03/2023"
        loc="Kherbit-Rouha"
      />
      <Project
        idd="carouselExampless"
        target="#carouselExampless"
        img1={Proj62}
        txt1="BEFORE"
        desc1="Module before WEE team interference"
        img2={Proj61}
        txt2="AFTER"
        desc2="Module installed by WEE team"
        img3={logo1}
        txt3="Watt Echo Energy"
        desc3="Your First choice for Electrical and solar solutions"
        img4={logo1}
        txt4="Watt Echo Energy"
        desc4="Your First choice for Electrical and solar solutions"
        customer="Quick Meal S.A.L"
        system="Electric Backup System For Servers & Cameras"
        spec1="Warranty on installation"
        spec2="1 * Felicity Lithium Battery 7.2 KWh"
        spec3="1 * Inverter Voltronic VM3-6KW"
        datee="25/03/2023"
        loc="Taalabiya"
      />
      <Project
        idd="carouselExamplesss"
        target="#carouselExamplesss"
        img1={Roofv}
        txt1="Roof View"
        desc1="High Quality panels built"
        img2={WorkP}
        txt2="Work On Progress"
        desc2="Our Engineering team workin on the Panel"
        img3={PanelB}
        txt3="Heavy Duty Panel Building"
        desc3="Panel designed and built by WEE team"
        img4={Module}
        txt4="Overview of the whole Module"
        desc4="Inverter + Controller built and ready to be used"
        customer="Home Design Factory"
        system="PV Diesel System"
        spec1="Eelectrical Design & Panel Building"
        spec2="1 * Inverter Huawei 100KW"
        spec3="1* Hybrid controller DEIF AGC 150"
        datee="29/4/2023"
        loc="Qab-Elias Mandara"
      />
      <Project
        idd="carousel"
        target="#carousel"
        img1={United3}
        txt1="Roof View"
        desc1="JA solar panels built by our engineering team."
        img2={United1}
        txt2="Installing Panels"
        desc2="WEE Engineers and Technicians Installing Panels"
        img3={United2}
        txt3="Electrical Room"
        desc3="Overview of the electrical room"
        img4={United4}
        txt4="MDB"
        desc4="Overview of the MDB installation"
        customer="United for Business"
        system="On Grid / PV diesel System"
        spec1="176 KWp"
        spec2="320 * JA Solar Panels 550 Watts"
        spec3="2 * Inverters Huawei 100 KW"
        spec4="1 * Deif Hybrid Controller"
        datee="08/08/2023"
        loc="Taaneyel"
      />
      <ScrollToUp />
    </>
  );
}

export default Projects;
